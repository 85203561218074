import { AuthModel } from "../models/authModel";
import axios from "./apiclient";

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user/profile/`;
export const LOGIN_URL = `${API_URL}/token/`;

// Server should return AuthModel
export function login(email: string, password: string, userAgent: string) {
  return axios.post<AuthModel>(
    LOGIN_URL,
    {
      email,
      password,
      weblogin: "true",
      useragent: userAgent,
    },
    {
      headers: {
        "Content-Type": "application/json",
        DEVICE: "WEB",
      },
    }
  );
}
export async function ForgotPasswordApi(email: string) {
  const res = await axios.post(`${API_URL}/forgetpassword/email/`, { email });
  return res.data;
}
export async function verifyOTP({
  token,
  key,
}: {
  token: string;
  key: string;
}) {
  const res = await axios.post(`${API_URL}/user/password/verification/`, {
    token,
    key,
  });
  return res.data;
}
export async function ResetPassword({
  code,
  key,
  new_password,
  email,
  confirm_password,
}: {
  code: string;
  key: string;
  new_password: string;
  email: string;
  confirm_password: string;
}) {
  const res = await axios.post(`${API_URL}/user/change/forgotpassword/`, {
    code,
    key,
    new_password,
    email,
    confirm_password,
  });
  return res.data;
}
export async function LoginWithGoogleToken(token: string) {
  console.log(token);
  const data = {
    token: token.toString(),
  };
  const res = await axios.post(`${API_URL}/auth/login/google`, data);
  return res.data;
}

export async function getUserByToken(token: string) {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
      DEVICE: "WEB",
    },
  };
  const res = await axios.get(GET_USER_BY_ACCESSTOKEN_URL, config);
  return res.data;
}

export async function GetRefreshToken() {
  const token = JSON.parse(localStorage.getItem("asp-authv2") || "{}");
  let config = {
    headers: {
      Authorization: "Bearer " + token.access,
      DEVICE: "WEB",
    },
  };
  const res = await axios.post(
    `${API_URL}/token/refresh/`,
    {
      refresh: token.refresh,
    },
    config
  );
  return res.data;
}

export async function UserLogout() {
  const token = JSON.parse(localStorage.getItem("asp-authv2") || "{}");

  let config = {
    headers: {
      Authorization: "Bearer " + token.access,
      DEVICE: "WEB",
    },
  };
  let data = {
    token: token.refresh,
    weblogin: "true",
  };
  const res = await axios.post(`${API_URL}/logout/`, data, config);

  return res;
}
