import { FC } from "react";
import Loader from "./Loader";
interface Props {
  isLoading: boolean;
  children?: any;
}
const LoadingWrapper: FC<Props> = ({ isLoading, children }) => {
  return isLoading ? <Loader /> : <>{children}</>;
};
export default LoadingWrapper;
