import { useEffect, useState } from "react";
// import axios from "axios";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Accordion, Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import apiClient from "../../api/apiclient";
import { SubjectModel } from "../../models/subjectModel";
import LoadingWrapper from "../../components/ui/LoadingWrapper";
import LoginModal from "../../components/modals/LoginModal";
// import handlePaymentIntegration, {
//   ENROLLMENT_TYPE,
// } from "../../utils/PaymentIntegration";
import { useAuth } from "../../context/auth";
import { handleEnrollForFree } from "../../api/enroll";
import { initiateKhaltiApi } from "../../api/khalti-new";
import { SubjectChapter } from "../../models/subjectModel";

const SubjectDetail = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState<SubjectModel>();
  const [subjectChapter, setSubjectChapter] = useState<SubjectChapter[]>();
  const [contentType, setContentType] = useState<string>("");
  const { id: slug } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // Login Modal
  const [showLogin, setShowLogin] = useState(false);
  // Payment Processing
  const [payLoading, setPayLoading] = useState(false);
  // Enroll for free
  const [enrollingForFree, setEnrollingForFree] = useState(false);

  const getData = async () => {
    // const { data } = await axios.get(
    //   `${process.env.REACT_APP_API_URL}/subject/${slug}/`
    // );
    // setData(data);
    // setIsLoading(false);
    apiClient
      .get(`${process.env.REACT_APP_API_URL}/subject/${slug}/`)
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const message = searchParams.get("message");
    const mobile = searchParams.get("mobile");
    if (message) {
      toast.error(message);
    } else if (mobile) {
      toast.success("Course Purchased Successfully");
    }
    // eslint-disable-next-line
  }, []);

  // const handleKhaltiPayment = async () => {
  //   try {
  //     const url = window.location.href;
  //     const { data } = await axios.post(
  //       `https://cors-anywhere.herokuapp.com/${process.env.REACT_APP_KHALTI_URL}/epayment/initiate/`,
  //       {
  //         return_url: url,
  //         website_url: url,
  //         amount: "10000",
  //         purchase_order_id: "1",
  //         purchase_order_name: "test",
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Key ${process.env.REACT_APP_KHALTI_PRIVATE_KEY}`,
  //         },
  //       }
  //     );
  //     // window.open(data.payment_url, "_blank", "noopener noreferrer");
  //     if (data.payment_url) {
  //       window.location.href = data.payment_url;
  //     }
  //   } catch (error) {
  //     toast.error("Error! Please try again later");
  //   }
  // };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSubjectChapter(data?.subject_chapter);
    setContentType("all");
  }, [data]);

  const handleContentChange = (
    event: React.MouseEvent<HTMLAnchorElement>,
    type: string
  ) => {
    event.preventDefault();
    let cType = type.toLowerCase();
    if (cType === "all") {
      setSubjectChapter(data?.subject_chapter);
    } else {
      setSubjectChapter(
        data?.subject_chapter.filter(
          ({ content_type }) => content_type === cType
        )
      );
    }
    setContentType(cType);
  };

  return (
    <LoadingWrapper isLoading={isLoading}>
      <div className="container">
        <main className="my-5">
          <div className="row">
            <div className="mt-3 col-12 col-lg-9 order-2 order-md-1 d-flex flex-column">
              <h1>{data?.name}</h1>
              <p>{data?.description}</p>
              {data && data?.subject_chapter?.length > 0 && (
                <>
                  <div className="d-flex justify-content-between">
                    <h4 className="text-primary fw-bold pt-3">
                      Subject Chapter
                    </h4>
                    <div>
                      <ul className="nav nav-pills nav-fill">
                        {["All", "Theory", "Video", "Formula"].map(
                          (name: string) => (
                            <li className="nav-item me-2">
                              <a
                                className={`nav-link ${name.toLowerCase() === contentType
                                  ? "active"
                                  : ""
                                  }`}
                                href="#"
                                onClick={(e) => handleContentChange(e, name)}
                              >
                                {name}
                              </a>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                  <Accordion>
                    {subjectChapter &&
                      subjectChapter.map(({ name, description }, idx) => {
                        return (
                          <Accordion.Item key={idx} eventKey={`${idx}`}>
                            <Accordion.Header>{name}</Accordion.Header>
                            <Accordion.Body>
                              {description || (
                                <span className="text-secondary">
                                  <small>Description not available.</small>
                                </span>
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })}
                  </Accordion>
                </>
              )}
            </div>
            <div className="mt-3 col-12 order-1 order-md-2 col-lg-3">
              <Card>
                <Card.Img
                  variant="top"
                  src={data?.image ?? "/media/icons/no-image-placeholder.svg"}
                  style={{ width: "100%", height: "250px", objectFit: "cover" }}
                />
                <Card.Body>
                  <Card.Title className="fs-7 text-primary fw-bold">
                    {data?.name}
                  </Card.Title>
                  {/* {!data?.is_enrolled && (
                    <Card.Title className="fs-7 text-secondary fw-bold">
                      Rs. {data?.price}
                    </Card.Title>
                  )} */}
                  <div className="d-flex">
                    {/* {!data?.is_enrolled ? (
                      data?.price && parseInt(data?.price) !== 0 ? (
                        <button
                          className="btn btn-primary success w-100 me-2"
                          onClick={() => {
                            if (!currentUser) setShowLogin(true);
                            else
                              handleEnrollForFree(
                                "subject",
                                data?.id,
                                setEnrollingForFree
                              );
                          }}
                          disabled={enrollingForFree}
                        >
                          {enrollingForFree
                            ? "Processing..."
                            : "Enroll for Free"}
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary success w-50 me-2"
                          onClick={() => {
                            if (!currentUser) setShowLogin(true);
                            else {
                              // handlePaymentIntegration(
                              //   {
                              //     name: data?.name,
                              //     url: window.location.href,
                              //     id: data?.id,
                              //     price: data?.price,
                              //   },
                              //   ENROLLMENT_TYPE?.subject?.name,
                              //   setPayLoading
                              // );
                              if (data?.id && data?.slug && data?.price) {
                                initiateKhaltiApi(
                                  "subject",
                                  setPayLoading,
                                  data?.id,
                                  data?.slug,
                                  data?.price
                                );
                              }
                            }
                          }}
                          disabled={payLoading}
                        >
                          {payLoading ? "Processing..." : "Buy Now"}
                        </button>
                      )
                    ) : (
                      <Button
                        variant="success w-50 me-2"
                        href={`/subjects/${slug}`}
                      >
                        Start
                      </Button>
                    )} */}
                    {
                      data?.is_enrolled ? (
                        <Button
                          variant="primary"
                          // href={`/subjects/${slug}/practice-quiz`}
                          onClick={() => {
                            if (!currentUser) setShowLogin(true);
                            else navigate(`/subjects/${slug}/practice-quiz`);
                          }}
                        >
                          Practice Quiz
                        </Button>
                      ) : (
                        <Button
                          variant="primary w-100"
                          onClick={() => {
                            if (!currentUser) {
                              toast.error("Please login to take the quiz")
                              setShowLogin(true)
                            }
                            else navigate(`/subjects/${slug}/trail/practice-quiz`)
                          }
                          }
                        >
                          Take a Trail Quiz
                        </Button>
                      )
                    }

                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
          {showLogin && (
            <LoginModal show={showLogin} onClose={() => setShowLogin(false)} />
          )}
        </main>
      </div>
    </LoadingWrapper>
  );
};

export default SubjectDetail;
