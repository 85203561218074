import MultiItemSlider from "../components/sliders/MultiItemSlider";
import Hero from "../components/layouts/Hero";
import CategorySlider from "../components/sliders/CategorySlider";
import ContinueLearningCard from "../components/ui/ContinueLearningCard";
import { useEffect, useState } from "react";

import { HomepageModel } from "../models/homepageModel";
import { EnrollmentModel } from "../models/enrollementModel";
import { getEnrollment } from "../api/enrollment";
import { useAuth } from "../context/auth";
import Loader from "../components/ui/Loader";
import LoadingWrapper from "../components/ui/LoadingWrapper";
import { url } from "inspector";
import HomeFeatures from "../components/ui/home/features";
import Blogs from "../components/ui/home/blogs";
import Faqs from "../components/ui/home/faq";
import NewFooter from "../components/layouts/NewFooter";
import Testimonials from "../components/ui/home/testimonials";
import TestimonialSlider from "../components/sliders/TestimonialSlider";
import axios from "../api/apiclient";

const Testpage = () => {
  const [data, setData] = useState<HomepageModel>({});
  const [enrollmentData, setEnrollmentData] = useState<EnrollmentModel>();
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useAuth();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/home/`)
      .then((value) => setData(value.data.results))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const getEnrollmentData = async () => {
    const { data } = await getEnrollment();
    setEnrollmentData(data);
  };

  useEffect(() => {
    currentUser && getEnrollmentData();
  }, []);

  return (
    <>
      <LoadingWrapper isLoading={isLoading}></LoadingWrapper>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Hero banners={data.banner} />
          <main>
            <div className="container">
              <HomeFeatures />
              <Blogs
                heading="Our Courses"
                subHeading="With our integrated CRM, project management, collaboration and
                invoicing capabilities, you can manage your business in one
                secure platform."
                items={data?.trending_courses?.slice(0, 3)?.map((d) => ({
                  img: d?.image,
                  title: d?.name,
                  subTitle: d?.description,
                  href: `/courses/${d?.slug}`,
                }))}
              />
            </div>
            <TestimonialSlider />
            <div className="container">
              <Faqs />
            </div>
          </main>
        </>
      )}
    </>
  );
};

export default Testpage;
