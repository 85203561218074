import apiclient, { AxiosError, AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import { GetRefreshToken, UserLogout } from "./auth";
import { toast } from "react-toastify";
import { redirect } from "react-router-dom";

const axios = apiclient.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 20000,
  headers: {
    DEVICE: "WEB",
  },
});

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("asp-authv2");
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${JSON.parse(token)?.access}`;
      config.headers.DEVICE = "WEB";
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const token = JSON.parse(localStorage.getItem("asp-authv2")!);

    if (!token?.access) {
      return Promise.reject(error);
    }
    let config = {
      headers: {
        Authorization: "Bearer " + token.access,
        DEVICE: "WEB",
      },
    };
    if (error.response.status === 401) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/token/refresh/`,
          {
            refresh: token.refresh,
          },
          config
        )
        .then((data: any) => {
          const token = JSON.parse(localStorage.getItem("asp-authv2") || "{}");
          const newToken = { ...token, access: data.data.access };
          localStorage.setItem("asp-authv2", JSON.stringify(newToken));
          window.location.reload();
        })
        .catch((err) => {
          localStorage.removeItem("asp-authv2");
          window.location.href = "/";
        });
    }
    return Promise.reject(error);
  }
);

const useAxios = <T>(params: AxiosRequestConfig) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [data, setData] = useState<T>();

  const fetchData = async () => {
    try {
      const res = await axios.request(params);
      setData(res.data as T);
    } catch (err) {
      if (apiclient.isAxiosError(err)) {
        const error = err as AxiosError<{ detail: string }>;
        setError(error.response?.data.detail);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return [loading, error, data] as const;
};

export { useAxios, AxiosError };
export default axios;
