import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import LoadingWrapper from "../components/ui/LoadingWrapper";
import { FaqModel } from "../models/faqModel";
import axios from "../api/apiclient";

const Faq = () => {
  const [data, setData] = useState<FaqModel>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/faqs/`)
      .then((value) => setData(value.data))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <LoadingWrapper isLoading={isLoading}>
      <div className="mt-5 mt-xl-8 text-white">
        <div className="container">
          <h4 className="text-primary fw-bold pt-3">
            Frequently Asked Question
          </h4>
          {data ? (
            <>
              {data.results.map((e, idx) => {
                return (
                  <Accordion key={idx}>
                    <Accordion.Item eventKey={`${idx}`}>
                      <Accordion.Header>{e.question}</Accordion.Header>
                      <Accordion.Body>{e.answer}</Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </LoadingWrapper>
  );
};
export default Faq;
