import React, {
  FC,
  createContext,
  useContext,
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
} from "react";
import Loader from "../components/ui/Loader";

const SplashScreenContext = createContext<
  Dispatch<SetStateAction<number>> | undefined
>(undefined);
interface Props {
  children: any;
}

const disableSplashScreen = () => {
  const splashScreen = document.getElementById("splash-screen");
  if (splashScreen) {
    splashScreen.style.setProperty("display", "none");
  }
};

const SplashScreenProvider: FC<Props> = ({ children }) => {
  const [count, setCount] = useState(0);
  let visible = count > 0;

  useEffect(() => {
    const splashScreen = document.getElementById("splash-screen");

    // Show SplashScreen
    if (splashScreen && visible) {
      splashScreen.classList.remove("hidden");

      return () => {
        splashScreen.classList.add("hidden");
      };
    }

    // Hide SplashScreen
    let timeout: number;
    if (splashScreen && !visible) {
      timeout = window.setTimeout(() => {
        splashScreen.classList.add("hidden");
      }, 1500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [visible]);

  useEffect(() => {
    disableSplashScreen();
  }, []);

  return (
    <SplashScreenContext.Provider value={setCount}>
      {children}
    </SplashScreenContext.Provider>
  );
};

const LayoutSplashScreen: FC<{ visible?: boolean }> = ({ visible = false }) => {
  // Everything are ready - remove splashscreen
  const setCount = useContext(SplashScreenContext);

  useEffect(() => {
    if (!visible) {
      return;
    }

    if (setCount) {
      setCount((prev) => {
        return prev + 1;
      });
    }

    return () => {
      if (setCount) {
        setCount((prev) => {
          return prev - 1;
        });
      }
    };
  }, [setCount, visible]);

  return (
    <div>
      {/* loading */}
      <Loader />
    </div>
  );
};

export { SplashScreenProvider, LayoutSplashScreen };
