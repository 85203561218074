import { Accordion } from "react-bootstrap";
import "../../../../styles/FaqAccordion.css";

const Faqs = () => {
  return (
    <section className="position-relative  my-5 bg-white overflow-hidden">
      <div className="position-absolute top-0 start-0 h-100 w-100"></div>
      <div className="position-relative container" style={{ zIndex: 1 }}>
        <div className="mb-5 mw-md-5xl text-center mw-md-4xl mx-auto">
          <h2
            className="font-heading mb-4 text-large"
            style={{ letterSpacing: -1 }}
          >
            Frequently Asked Questions
          </h2>
          <p className="mb-0 text-secondary section-subheading">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
          </p>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            <Accordion className="faq-accordion">
              <Accordion.Item
                eventKey="0"
                className="px-3 py-4 bg-light-light mb-4"
              >
                <Accordion.Header className="bg-light-light header">
                  <h5 className="fw-bold">Why is this website?</h5>
                </Accordion.Header>
                <Accordion.Body className="text-secondary fw-semi-semibold">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey="1"
                className="px-3 py-4 bg-light-light mb-4"
              >
                <Accordion.Header className="bg-light-light header">
                  <h5 className="fw-bold">Why is this website?</h5>
                </Accordion.Header>
                <Accordion.Body className="text-secondary fw-semi-semibold">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey="2"
                className="px-3 py-4 bg-light-light mb-4"
              >
                <Accordion.Header className="bg-light-light header">
                  <h5 className="fw-bold">Why is this website?</h5>
                </Accordion.Header>
                <Accordion.Body className="text-secondary fw-semi-semibold">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey="3"
                className="px-3 py-4 bg-light-light mb-4"
              >
                <Accordion.Header className="bg-light-light header">
                  <h5 className="fw-bold">Why is this website?</h5>
                </Accordion.Header>
                <Accordion.Body className="text-secondary fw-semi-semibold">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey="4"
                className="px-3 py-4 bg-light-light mb-4"
              >
                <Accordion.Header className="bg-light-light header">
                  <h5 className="fw-bold">Why is this website?</h5>
                </Accordion.Header>
                <Accordion.Body className="text-secondary fw-semi-semibold">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
