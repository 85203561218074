const HomeFeatures = () => {
  return (
    <section
      className="position-relative py-5 my-5 bg-white overflow-hidden"
      style={{ backgroundImage: "url(/media/images/pattern-light.png)" }}
    >
      <div
        className="position-absolute top-0 start-0 h-100 w-100"
        style={{
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
        }}
      ></div>
      <div className="position-relative container" style={{ zIndex: 1 }}>
        <div className="mb-5 pb-5 text-center mw-md-5xl mx-auto">
          <h2
            className="font-heading mb-4 text-large"
            style={{ letterSpacing: -1 }}
          >
            Gain more insight into how people use your
          </h2>
          <p className="mb-0 text-secondary section-subheading">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took.
          </p>
        </div>
        <div className="mw-md-6xl px-3">
          <div className="row g-5">
            <div className="mb-8 col-12 col-md-4">
              <div className="position-relative py-5 px-4 h-100 bg-light-light rounded">
                <div
                  className="position-absolute start-50 translate-middle-x mt-n7 border border-5 border-white overflow-hidden rounded-pill"
                  style={{ top: -30 }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center fs-9 text-white fw-semibold bg-primary"
                    style={{ width: 48, height: 48 }}
                  >
                    1
                  </div>
                </div>
                <h3 className="mb-4 fs-5 fw-bold text-center">
                  Measure your performance
                </h3>
                <p className="mb-0 fw-semi-semibold text-secondary text-center">
                  Stay connected with your team and make quick decisions
                  wherever you are.
                </p>
              </div>
            </div>
            <div className="mb-8 col-12 col-md-4">
              <div className="position-relative py-5 px-4 h-100 bg-light-light rounded">
                <div
                  className="position-absolute start-50 translate-middle-x mt-n7 border border-5 border-white overflow-hidden rounded-pill"
                  style={{ top: -30 }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center fs-9 text-white fw-semibold bg-primary"
                    style={{ width: 48, height: 48 }}
                  >
                    2
                  </div>
                </div>
                <h3 className="mb-4 fs-5 fw-bold text-center">
                  Custom analytics
                </h3>
                <p className="mb-0 fw-semi-semibold text-secondary text-center">
                  Get a complete sales dashboard in the cloud. See activity,
                  revenue and social metrics all in one place.
                </p>
              </div>
            </div>
            <div className="mb-8 col-12 col-md-4">
              <div className="position-relative py-5 px-4 h-100 bg-light-light rounded">
                <div
                  className="position-absolute start-50 translate-middle-x mt-n7 border border-5 border-white overflow-hidden rounded-pill"
                  style={{ top: -30 }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center fs-9 text-white fw-semibold bg-primary"
                    style={{ width: 48, height: 48 }}
                  >
                    3
                  </div>
                </div>
                <h3 className="mb-4 fs-5 fw-bold text-center">
                  Team Management
                </h3>
                <p className="mb-0 fw-semi-semibold text-secondary text-center">
                  Our calendar lets you know what is happening with customer and
                  projects to control process.
                </p>
              </div>
            </div>
            <div className="mb-8 col-12 col-md-4">
              <div className="position-relative py-5 px-4 h-100 bg-light-light rounded">
                <div
                  className="position-absolute start-50 translate-middle-x mt-n7 border border-5 border-white overflow-hidden rounded-pill"
                  style={{ top: -30 }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center fs-9 text-white fw-semibold bg-primary"
                    style={{ width: 48, height: 48 }}
                  >
                    4
                  </div>
                </div>
                <h3 className="mb-4 fs-5 fw-bold text-center">
                  Measure your performance
                </h3>
                <p className="mb-0 fw-semi-semibold text-secondary text-center">
                  Stay connected with your team and make quick decisions
                  wherever you are.
                </p>
              </div>
            </div>
            <div className="mb-8 col-12 col-md-4">
              <div className="position-relative py-5 px-4 h-100 bg-light-light rounded">
                <div
                  className="position-absolute start-50 translate-middle-x mt-n7 border border-5 border-white overflow-hidden rounded-pill"
                  style={{ top: -30 }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center fs-9 text-white fw-semibold bg-primary"
                    style={{ width: 48, height: 48 }}
                  >
                    5
                  </div>
                </div>
                <h3 className="mb-4 fs-5 fw-bold text-center">
                  Custom analytics
                </h3>
                <p className="mb-0 fw-semi-semibold text-secondary text-center">
                  Get a complete sales dashboard in the cloud. See activity,
                  revenue and social metrics all in one place.
                </p>
              </div>
            </div>
            <div className="mb-8 col-12 col-md-4">
              <div className="position-relative py-5 px-4 h-100 bg-light-light rounded">
                <div
                  className="position-absolute start-50 translate-middle-x mt-n7 border border-5 border-white overflow-hidden rounded-pill"
                  style={{ top: -30 }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center fs-9 text-white fw-semibold bg-primary"
                    style={{ width: 48, height: 48 }}
                  >
                    6
                  </div>
                </div>
                <h3 className="mb-4 fs-5 fw-bold text-center">
                  Team Management
                </h3>
                <p className="mb-0 fw-semi-semibold text-secondary text-center">
                  Our calendar lets you know what is happening with customer and
                  projects to control process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeFeatures;
