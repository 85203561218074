import { Link } from "react-router-dom";

interface Item {
  img?: string;
  title?: string;
  subTitle?: string;
  href?: string;
}

interface Props {
  heading: string;
  subHeading?: string;
  items?: Item[];
}

const Blogs = ({ heading, subHeading, items }: Props) => {
  return (
    <section className="position-relative bg-white overflow-hidden">
      <div className="w-100 h-100 position-absolute top-0"></div>
      <div className="position-relative container" style={{ zIndex: 1 }}>
        <div className="mb-5 row align-items-center g-8">
          <div className="col-12 col-md-8 mb-3">
            <div className="mw-md-5xl">
              <h2
                className="font-heading mb-4 text-large"
                style={{ letterSpacing: -1 }}
              >
                {heading}
              </h2>
              {/* {subHeading && (
                <p className="mb-0 text-secondary section-subheading">
                  {subHeading}
                </p>
              )} */}

              <p className="mb-0 text-secondary section-subheading">
                Choose from over 1,400 courses in topics like business
                analytics, graphic design, Python, and more.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-3 offset-md-1">
            <div className="row">
              <div className="col-12 col-md-12">
                <a
                  className="btn btn-md btn-primary d-flex justify-content-center align-items-center fs-12 py-3 px-3 text-success-light shadow bg-secondary-dark"
                  href="#"
                >
                  Explore More Courses
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-8">
          {items?.length
            ? items?.map((d, idx) => (
                <div
                  className="col-12 col-md-4 d-flex flex-column align-items-start mb-5"
                  key={idx}
                >
                  <Link to={d?.href || "#"} className="w-100">
                    <img
                      className="mb-3 w-100 rounded"
                      src={d?.img || "/media/images/placeholder.png"}
                      alt={d?.title}
                      height={300}
                      style={{ objectFit: "cover" }}
                    />
                  </Link>

                  <Link
                    className="mb-3 d-inline-block text-decoration-none"
                    to={d?.href || "#"}
                  >
                    <h3 className="font-heading fs-5 fw-bold mb-0 text-light-dark link-light-dark">
                      {d?.title}
                    </h3>
                  </Link>
                  {d?.subTitle && (
                    <p className="mb-4 text-secondary fs-10 fw-semi-semibold">
                      {d?.subTitle}
                    </p>
                  )}
                  <Link to={d?.href || "#"}>
                    <span className="badge shadow bg-primary text-white px-3 py-3 fw-semi-semibold">
                      Learn More
                    </span>
                  </Link>
                </div>
              ))
            : null}
          {/* <div className="col-12 col-md-4">
            <img
              className="mb-3 w-100"
              src="/media/images/blogs/news-block.png"
              alt=""
            />

            <a className="mb-3 d-inline-block text-decoration-none" href="#">
              <h3 className="font-heading fs-5 fw-bold mb-0 text-light-dark link-light-dark">
                A small business is only as good as its tools and it is totally
                true.
              </h3>
            </a>
            <p className="mb-4 text-secondary fs-10 fw-semi-semibold">
              We've all experienced the chaos of multiple spreadsheets, tracking
              and insight tools, and scrambling for the right data at the right
              time...
            </p>
            <a href="#">
              <span className="badge text-uppercase shadow bg-primary text-white p-2">
                LEARN MORE...
              </span>
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Blogs;
