import { FC } from "react";
import Pagination from "react-bootstrap/Pagination";
import { Link } from "react-router-dom";
import { Pagination as IPagination } from "../../models/paginationModel";

interface Props {
  data: IPagination;
  url: string;
  offset: number;
}
const CustomPagination: FC<Props> = ({
  data: { count, next, previous },
  offset,
  url,
}) => {
  const limit = 2;
  const currentPage = offset / limit + 1;
  const getPagination = (count: number) => {
    const pg = [];
    for (var i = 1; i <= count / limit; i++) {
      const newOffset = i * limit - limit;
      pg.push(
        <li className="page-item" key={i}>
          <Link
            key={i}
            className={`page-link ${
              i === Number(currentPage) ? "active" : ""
            } `}
            to={`${url}?offset=${newOffset}&limit=${limit}`}
          >
            {i === Number(currentPage) ? (
              <span style={{ pointerEvents: "none" }}>{i}</span>
            ) : (
              <span>{i}</span>
            )}
          </Link>
        </li>
      );
    }
    return pg;
  };
  return (
    <Pagination>
      <Pagination.Prev as={Link} href="/ttest" />
      <li className="page-item">
        <Link
          className="page-link"
          to={
            offset > 0 ? `${url}?offset=${offset - limit}&limit=${limit}` : "#"
          }
        >
          ‹
        </Link>
      </li>
      {getPagination(count)}
      <li className="page-item">
        <Link
          className="page-link"
          to={
            offset < count
              ? `${url}?offset=${offset + limit}&limit=${limit}`
              : "#"
          }
        >
          ›
        </Link>
      </li>
    </Pagination>
  );
};

export default CustomPagination;
