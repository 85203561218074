import { CustomArrowProps } from "react-slick";
import SVG from "react-inlinesvg";

import styles from "../../styles/SliderArrows.module.scss";

const SliderArrowL = (props: CustomArrowProps) => {
  const { className, style, onClick } = props;
  const customClasses = `${className} ${styles.arrow}`;
  return (
    <div className={customClasses} style={style} onClick={onClick}>
      <SVG src="https://cdn.asparksys.com/medias/1677651603326.svg" />
    </div>
  );
};

const SliderArrowR = (props: CustomArrowProps) => {
  const { className, style, onClick } = props;
  const customClasses = `${className} ${styles.arrow}`;
  return (
    <div className={customClasses} style={style} onClick={onClick}>
      <SVG src="https://cdn.asparksys.com/medias/1677651729887.svg" />
    </div>
  );
};

export { SliderArrowL, SliderArrowR };
