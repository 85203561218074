import { FC, lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import ScrollToTop from "./utils/ScrollToTop";

import { NavModel } from "./models/navModel";
import { useAxios } from "./api/apiclient";

import { ToastContainer } from "react-toastify";

import SubjectList from "./pages/subjects/SubjectList";
import Faq from "./pages/Faq";
import { LayoutSplashScreen } from "./utils/SplashScreen";
import LoadingWrapper from "./components/ui/LoadingWrapper";
import SubjectDetail from "./pages/subjects/SubjectDetail";
import Testpage from "./pages/TestPage";
const SubjectSkillTest = lazy(
  () => import("./pages/quiz/test-skill/SubjectSkillTest")
);
const NewFooter = lazy(() => import("./components/layouts/NewFooter")); // "./components/layouts/NewFooter";
// import QuizCourse from "./pages/subjects/QuizCourse";
const PracticeQuiz = lazy(() => import("./pages/courses/PracticeQuiz"));

const QuizSubject = lazy(() => import("./pages/subjects/QuizSubject")); // "./pages/subjects/QuizSubject";
const PrivateRoutes = lazy(() => import("./Routes/PrivateRoute")); // "./Routes/PrivateRoute";
const PageNotFound = lazy(() => import("./pages/PageNotFound")); // "./pages/PageNotFound";
const ChapterQuiz = lazy(() => import("./pages/subjects/ChapterQuiz")); // "./pages/subjects/ChapterQuiz";
const QuizSubjectTrail = lazy(
  () => import("./pages/subjects/QuizSubjectTrail")
);
const VerifyEmail = lazy(() => import("./pages/VerifyEmail"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const MainNav = lazy(() => import("./components/layouts/MainNav"));
const Subject = lazy(() => import("./pages/subjects/Subject"));
const Homepage = lazy(() => import("./pages/Homepage"));
const Profile = lazy(() => import("./pages/Profile"));
const Course = lazy(() => import("./pages/Course"));
const InfoPage = lazy(() => import("./pages/InfoPage"));
const Faculty = lazy(() => import("./pages/faculty/Faculty"));
const WeeklyCourseQuiz = lazy(() => import("./pages/quiz/weekly/course"));
const WeeklySubjectQuiz = lazy(() => import("./pages/quiz/weekly/subject"));
const DailyCourseQuiz = lazy(() => import("./pages/quiz/daily/course"));
const DailySubjectQuiz = lazy(() => import("./pages/quiz/daily/subject"));
const PaymentPage = lazy(() => import("./pages/payment"));
const ModelQuestionPractice = lazy(
  () => import("./pages/courses/ModelQuestionPractice")
);
const App = () => {
  console.log("hello folks");
  const handlePrint = (event: Event) => {
    const keyboardEvent = event as KeyboardEvent;
    const ctrl = keyboardEvent.ctrlKey || keyboardEvent.metaKey;
    const input_key = keyboardEvent.key;
    const disabled_key = ["I", "J", "C", "u", "p"];
    // const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent);
    if (
      input_key === "F12" ||
      (ctrl && disabled_key.includes(input_key)) ||
      (ctrl && keyboardEvent.shiftKey && disabled_key.includes(input_key))
    ) {
      keyboardEvent.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePrint);
    return () => {
      document.removeEventListener("keydown", handlePrint);
    };
  }, []);

  // to disable the context menu
  useEffect(() => {
    // define a custom handler function for the contextmenu event
    const handleContextMenu = (e: any) => {
      // prevent the right-click menu from appearing
      e.preventDefault();
    };
    // attach the event listener to the document object
    document.addEventListener("contextmenu", handleContextMenu);
    // clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  const [loading, , data] = useAxios<NavModel>({
    url: "/nav/",
  });

  if (loading) return <LayoutSplashScreen />;

  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <MainNav
          nav={data?.results?.categories}
          subject_enrolled={data?.results?.subject_enrolled}
        />
        <Routes>
          <Route
            index
            element={
              <SuspensedView>
                <Homepage />
              </SuspensedView>
            }
          />
          <Route
            path="test"
            element={
              <SuspensedView>
                <Testpage />
              </SuspensedView>
            }
          />
          <Route
            path=":id"
            element={
              <SuspensedView>
                <Faculty />
              </SuspensedView>
            }
          />

          <Route
            path="courses/:id"
            element={
              <SuspensedView>
                <Course />
              </SuspensedView>
            }
          />
          <Route
            path="course/:id/model-questions/"
            element={
              <SuspensedView>
                <div className="container my-3">
                  <ModelQuestionPractice />
                </div>
              </SuspensedView>
            }
          />
          <Route
            path="subjects"
            element={
              <SuspensedView>
                <SubjectList />
              </SuspensedView>
            }
          />
          <Route
            path="subjects/:id/detail/"
            element={
              <SuspensedView>
                <SubjectDetail />
              </SuspensedView>
            }
          />
          <Route
            path="subjects/:id"
            element={
              <SuspensedView>
                <Subject />
              </SuspensedView>
            }
          />
          <Route
            path="subjects/:id/practice-quiz"
            element={
              <SuspensedView>
                <div className="container my-3">
                  {/* <QuizSlider /> */}
                  <QuizSubject />
                </div>
              </SuspensedView>
            }
          />
          <Route
            path="subjects/:id/trail/practice-quiz"
            element={
              <SuspensedView>
                <div className="container my-3">
                  {/* <QuizSlider /> */}
                  <QuizSubjectTrail />
                </div>
              </SuspensedView>
            }
          />
          <Route
            path="chapters/:id/practice-quiz"
            element={
              <SuspensedView>
                <div className="container my-5">
                  {/* <QuizProvider>
                    <QuizSlider />
                  </QuizProvider>
                   */}
                  <ChapterQuiz />
                </div>
              </SuspensedView>
            }
          />
          <Route
            path="chapters/:id/test-skill"
            element={
              <SuspensedView>
                <div className="container my-5">
                  {/* <QuizProvider>
                    <QuizSlider />
                  </QuizProvider>
                   */}
                  <SubjectSkillTest />
                </div>
              </SuspensedView>
            }
          />

          <Route
            path="course/:id/subject/practice-quiz"
            element={
              <SuspensedView>
                <PrivateRoutes>
                  <div className="container my-5">
                    <PracticeQuiz />
                    {/* <QuizSlider /> */}
                    {/* <QuizCourse /> */}
                  </div>
                </PrivateRoutes>
              </SuspensedView>
            }
          />

          <Route
            path="profile/*"
            element={
              <SuspensedView>
                <Profile />
              </SuspensedView>
            }
          />
          <Route
            path="quiz/weekly/course/:id"
            element={
              <SuspensedView>
                <WeeklyCourseQuiz />
              </SuspensedView>
            }
          />
          <Route
            path="quiz/weekly/subject/:id"
            element={
              <SuspensedView>
                <WeeklySubjectQuiz />
              </SuspensedView>
            }
          />
          <Route
            path="quiz/daily/course/:id"
            element={
              <SuspensedView>
                <DailyCourseQuiz />
              </SuspensedView>
            }
          />
          <Route
            path="quiz/daily/subject/:id"
            element={
              <SuspensedView>
                <DailySubjectQuiz />
              </SuspensedView>
            }
          />
          <Route
            path="about"
            element={
              <SuspensedView>
                <InfoPage data={data?.results?.pages?.about} />
              </SuspensedView>
            }
          />
          <Route
            path="privacy-policy"
            element={
              <SuspensedView>
                <InfoPage data={data?.results?.pages?.privacy_policy} />
              </SuspensedView>
            }
          />
          <Route
            path="refund-policy"
            element={
              <SuspensedView>
                <InfoPage data={data?.results?.pages?.refund_policy} />
              </SuspensedView>
            }
          />
          <Route
            path="terms-and-conditions"
            element={
              <SuspensedView>
                <InfoPage data={data?.results?.pages?.terms} />
              </SuspensedView>
            }
          />

          <Route
            path="faq"
            element={
              <SuspensedView>
                <Faq />
              </SuspensedView>
            }
          />

          <Route
            path="payment/verify"
            element={
              <SuspensedView>
                <PaymentPage />
              </SuspensedView>
            }
          />
          <Route path="/*" element={<PageNotFound />} />
          <Route path="/verify/email" element={<VerifyEmail />} />
          <Route path="/" element={<ForgotPassword />} />
          <Route path="not-found" element={<PageNotFound />} />
        </Routes>
        <NewFooter info={data?.results?.info} />
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
};

const SuspensedView: FC<{ children: JSX.Element }> = ({ children }) => {
  TopBarProgress.config({
    barColors: {
      "0": "#039546",
    },
    barThickness: 5,
    shadowBlur: 5,
  });
  return (
    <Suspense fallback={<LoadingWrapper isLoading={true} />}>
      {children}
    </Suspense>
  );
};

export default App;
