import Slider, { Settings } from "react-slick";
import { SliderArrowL, SliderArrowR } from "./SliderArrows";
import "../../styles/slider.scss";
import Testimonials from "../ui/home/testimonials";

const TestimonialSlider = () => {
  const settings: Settings = {
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SliderArrowL style={{ color: "white" }} />,
    nextArrow: <SliderArrowR />,
  };
  return (
    <div className="overflow-hidden py-5">
      {/* Curves */}
      <div className="d-flex mb-n1 text-primary">
        <svg
          className="w-100"
          viewBox="0 0 1440 116"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 64.5909H349.922C606.664 64.5909 859.771 -7.62939e-06 1080 -7.62939e-06C1300.23 -7.62939e-06 1440 64.5909 1440 64.5909V116H0V64.5909Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
      <div
        className="position-relative py-2 bg-primary"
        style={{
          backgroundImage:
            "url('https://cdn.asparksys.com/medias/1677650417603.png')",
        }}
      >
        {/* <div
          className="position-absolute top-0 start-0 h-100 w-100"
          style={{
            background:
              "radial-gradient(50% 50% at 50% 50%, rgba(42, 51, 66, 0) 0%, #2A3342 100%)",
          }}
        ></div> */}
        <div className="position-absolute top-0 start-0 h-100 w-100"></div>

        <div className="position-relative container py-5" style={{ zIndex: 1 }}>
          <Slider {...settings}>
            <Testimonials />
            <Testimonials />
          </Slider>
        </div>
      </div>
      {/* Curves */}
      <div className="d-flex mt-n1 text-primary">
        <svg
          className="w-100"
          viewBox="0 0 1440 116"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1440 51.4091H1090.08C833.336 51.4091 580.229 116 360 116C139.771 116 0 51.4091 0 51.4091V0H1440V51.4091Z"
            fill="currentcolor"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default TestimonialSlider;
