import { FilterParams } from "../models/paginationModel";
import axios from "./apiclient";

export function getEnrollment(filterParams?: FilterParams) {
  return axios.get(`${process.env.REACT_APP_API_URL}/enrollment/?offset=0`);
}

export async function modalQuestions() {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/modelquestion/`
  );
  return res.data;
}
