import clsx from "clsx";
import { FC } from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";

import styles from "../../styles/Card.module.scss";
import { TrendingCourse } from "../../models/homepageModel";

interface Props {
  course?: TrendingCourse;
}

const CourseCard: FC<Props> = ({ course }) => {
  return (
    <div className={clsx("card h-100")} style={{
      backgroundColor: "#fbf7f4"
    }}>
      <Link to={`/courses/${course?.slug}`}>
        <img
          className={clsx("card-img-top", styles.cardImage)}
          src={course?.image || "/media/icons/no-image-placeholder.svg"}
          alt="Course Cover"
        />
      </Link>
      <div className="card-body">
        <h6 className={"text-secondary card-title text-truncate fw-bold"}>
          <Link
            to={`/courses/${course?.slug}`}
            className="text-decoration-none"
          >
            {course?.name}
          </Link>
        </h6>
        <p
          style={{ height: 60, fontSize: 13 }}
          className={clsx("card-text text-muted", styles.cardText)}
        >
          {course?.description}
        </p>
        <Link to={`/courses/${course?.slug}`} className="btn btn-success">
          <div className="d-flex align-items-center">
            <span className="me-2">Learn More</span>
            <SVG src="/media/icons/caret-right-fill.svg" />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default CourseCard;
