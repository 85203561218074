import { useEffect, useState } from "react";

import clsx from "clsx";
import CourseCard from "../../components/ui/CourseCard";
import NoData from "../../components/ui/NoData";
import { FilterParams, Pagination } from "../../models/paginationModel";
import { SubjectModel } from "../../models/subjectModel";
import { useLocation } from "react-router-dom";
import CustomPagination from "../../components/ui/Pagination";
import LoadingWrapper from "../../components/ui/LoadingWrapper";
import axios from "../../api/apiclient";

export interface SubjectList extends Pagination {
  results: SubjectModel[];
}

const Faculty = () => {
  const [data, setData] = useState<SubjectList>();
  const [isLoading, setIsLoading] = useState(true);
  const { pathname, search } = useLocation();
  const [filterParams, setFilterParams] = useState<FilterParams>({
    offset: undefined,
    search: undefined,
  });

  const getData = async (filterParams: FilterParams) => {
    const { search = "", offset } = filterParams;
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/subject/?search=${search}&offset=${offset}`
    );
    setData(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData(filterParams);
  }, [filterParams]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const offset = searchParams.get("offset") || "";
    const searchText = searchParams.get("search") || "";
    setFilterParams({ offset: offset, search: searchText });
  }, [search]);

  return (
    <LoadingWrapper isLoading={isLoading}>
      <div className="container">
        <main className="my-5">
          <div className="row">
            {data && data.results.length > 0 ? (
              <>
                {data?.results.map((item) => (
                  <div
                    key={item.id}
                    className={clsx("mt-3 col-sm-12 col-md-4 mb-2")}
                  >
                    <CourseCard course={item} />
                  </div>
                ))}
                <CustomPagination
                  data={data}
                  url={pathname}
                  offset={
                    filterParams.offset ? parseInt(filterParams.offset) : 0
                  }
                />
              </>
            ) : (
              <NoData />
            )}
          </div>
        </main>
      </div>
    </LoadingWrapper>
  );
};

export default Faculty;
