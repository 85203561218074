import { FC, useCallback, useEffect, useState } from "react";
import {
  Modal,
  Button,
  FloatingLabel,
  Form,
  Alert,
  Spinner,
} from "react-bootstrap";
import { SubmitHandler, set, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios, { AxiosError, AxiosResponse } from "axios";
import {
  ForgotPasswordApi,
  LoginWithGoogleToken,
  ResetPassword,
  getUserByToken,
  login,
  verifyOTP,
} from "../../api/auth";
import { useAuth } from "../../context/auth";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
interface OTPResponse {
  id: number;
  email: string;
  key: string;
}
interface FieldValues {
  email: string;
  password: string;
}

interface ModalProps {
  show: boolean;
  onClose: () => void;
  hasCloseButton?: boolean;
  showSignUpBtn?: boolean;
  openRegisterModal?: () => void;
}

const dataSchema = yup.object({
  email: yup.string().email().required(),
  // password: yup.string().min(6).max(18).required(),
  password: yup.string().min(3).max(18).required(),
});

const LoginModal: FC<ModalProps> = ({
  show,
  onClose,
  hasCloseButton = true,
  showSignUpBtn = false,
  openRegisterModal = undefined,
}) => {
  const navigate = useNavigate();
  const [deviceInfo, setDeviceInfo] = useState("");

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const colorDepth = window.screen.colorDepth;
    const language = navigator.language;
    const memory = (navigator as any).deviceMemory || "unknown";
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const infoString = `${userAgent}${platform}${screenWidth}${screenHeight}${colorDepth}${language}${timezone}${memory}`;

    setDeviceInfo(infoString);
  }, []);

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const [success, setSuccess] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [otpResponse, setOtpResponse] = useState<OTPResponse>({
    id: 0,
    email: "",
    key: "",
  });
  const [otp, setOtp] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FieldValues>({
    resolver: yupResolver(dataSchema),
  });

  const onSubmit: SubmitHandler<FieldValues> = (values) => {
    setLoading(true);
    login(values.email, values.password, deviceInfo)
      .then(async (data: AxiosResponse) => {
        saveAuth(data.data);

        window.location.href = "/";
      })
      .catch((error) => {
        console.log(error);
        setError(error.response?.data?.detail || "Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const loginWithGoogle = useCallback(() => {
  //   const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth"
  //   const returnUrl = `https://redirectmeto.com/http:192.168.88.97:8000/api/v1/auth/login/google/`
  //   const scope = [
  //     "https://www.googleapis.com/auth/userinfo.email",
  //     "https://www.googleapis.com/auth/userinfo.profile"
  //   ].join(' ')
  //   const params = {
  //     response_type: "code",
  //     client_id: "781496603045-ocioa66dffvrh565mcfl5rrbijtpm8bv.apps.googleusercontent.com",
  //     redirect_uri: returnUrl,
  //     prompt: "select_account",
  //     access_type: "offline",
  //     scope: scope
  //   }
  //   const url = new URLSearchParams(params).toString()
  //   window.location.href = `${googleAuthUrl}?${url}`

  // }, [])
  const ForgotPassword = () => {
    if (watch("email")) {
      setLoading(true);
      ForgotPasswordApi(watch("email"))
        .then((res) => {
          setError("");
          setSuccess(true);
          setActivePage("otp");
          setOtpResponse(res);
          console.log(res);
        })
        .catch((error) => {
          setError(error.response?.data?.detail || "Something went wrong");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError("Please enter your email");
    }
  };
  const loginWithGoogleToken = (token: string) => {
    LoginWithGoogleToken(token)
      .then(async (data: any) => {
        console.log(data);
        saveAuth(data);
        await getUserByToken(data.access).then((data: AxiosResponse) => {
          setCurrentUser(data.data);
          onClose();
          window.location.reload();
        });
      })
      .catch((error) => {
        setError(error.response?.data?.detail || "Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const loginWithGoogle = useGoogleLogin({
    flow: "implicit",
    onSuccess: (response) => {
      console.log(response);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const resendLink = () => {
    if (watch("email")) {
      setLoading(true);
      ForgotPasswordApi(watch("email"))
        .then((res) => {
          setError("");
          console.log(res);
          setOtpResponse(res);
        })
        .catch((error) => {
          setError(error.response?.data?.detail || "Something went wrong");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError("Email is required");
    }
  };
  const verifyOtp = () => {
    if (otp) {
      setLoading(true);
      verifyOTP({
        token: otp,
        key: otpResponse.key,
      })
        .then((data: AxiosResponse) => {
          console.log(data);
          setActivePage("newPassword");
          setError("");
          setSuccess(true);
        })
        .catch((error) => {
          setError(error.response?.data?.detail || "Something went wrong");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError("Please enter OTP");
    }
  };
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const resetPassword = () => {
    if (newPassword && confirmPassword) {
      if (newPassword === confirmPassword) {
        setLoading(true);
        ResetPassword({
          email: watch("email"),
          code: otp,
          key: otpResponse.key,
          new_password: newPassword,
          confirm_password: confirmPassword,
        })
          .then(async (data: AxiosResponse) => {
            setError("");
            toast.success("Password reset successfully");
            setActivePage("");
          })
          .catch((error) => {
            setError(error.response?.data?.detail || "Something went wrong");
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setError("Passwords do not match");
      }
    } else {
      setError("Please enter new password and confirm password");
    }
  };
  if (activePage === "otp") {
    return (
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={onClose}
        backdrop={hasCloseButton ? undefined : "static"}
      >
        <Modal.Header closeButton={hasCloseButton}>
          <Modal.Title id="contained-modal-title-vcenter">
            Verify OTP
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="d-flex justify-content-center">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputStyle={{ width: "2rem", height: "2rem" }}
                containerStyle={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "1rem",
                }}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <small className="text-danger">{errors.email?.message}</small>

            {/* forgot password */}
            {success && (
              <Alert variant="success" className="mt-3 mb-0">
                We have sent you an otp on your email.
                {/* did not receive mail resend link */}
                <br />
                Did not receive mail?{" "}
                <button
                  type="button"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    color: "blue",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => resendLink()}
                >
                  Resend
                </button>{" "}
                <br />
              </Alert>
            )}
            {error && (
              <Alert variant="danger" className="mt-3 mb-0">
                {error}
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={loading}
              onClick={verifyOtp}
              type="button"
              className="w-100 mt-3"
            >
              {loading ? (
                <>
                  <Spinner animation="grow" size="sm" />
                  <Spinner animation="grow" />
                </>
              ) : (
                "Verify"
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  if (activePage === "newPassword") {
    return (
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={onClose}
        backdrop={hasCloseButton ? undefined : "static"}
      >
        <Modal.Header closeButton={hasCloseButton}>
          <Modal.Title id="contained-modal-title-vcenter">Login</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <FloatingLabel controlId="floatingInput" label="New Password">
              <Form.Control
                type="text"
                placeholder=""
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </FloatingLabel>
            <small className="text-danger"></small>
            <FloatingLabel
              className="mt-3"
              controlId="floatingPassword"
              label="Confirm Password"
            >
              <Form.Control
                type="password"
                placeholder=""
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FloatingLabel>
            <small className="text-danger">{errors.password?.message}</small>
            {/* forgot password */}

            {success && (
              <Alert variant="success" className="mt-3 mb-0">
                Please Set a new password
              </Alert>
            )}

            {error && (
              <Alert variant="danger" className="mt-3 mb-0">
                {error}
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={loading}
              type="button"
              onClick={resetPassword}
              className="w-100 mt-3"
            >
              {loading ? (
                <>
                  <Spinner animation="grow" size="sm" />
                  <Spinner animation="grow" />
                </>
              ) : (
                "Change Password"
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
      backdrop={hasCloseButton ? undefined : "static"}
    >
      <Modal.Header closeButton={hasCloseButton}>
        <Modal.Title id="contained-modal-title-vcenter">Login</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <FloatingLabel controlId="floatingInput" label="Email address">
            <Form.Control
              {...register("email")}
              type="email"
              placeholder="name@example.com"
            />
          </FloatingLabel>
          <small className="text-danger">{errors.email?.message}</small>
          <FloatingLabel
            className="mt-3"
            controlId="floatingPassword"
            label="Password"
          >
            <Form.Control
              {...register("password")}
              type="password"
              placeholder="Password"
            />
          </FloatingLabel>
          <small className="text-danger">{errors.password?.message}</small>
          {/* forgot password */}
          <div
            className="d-flex justify-content-end mt-3"
            style={{ cursor: "pointer" }}
          >
            <button
              type="button"
              className="text-decoration-none text-primary border-0 "
              style={{
                background: "none",
                cursor: "pointer",
              }}
              disabled={loading}
              onClick={() => ForgotPassword()}
            >
              {loading ? (
                <>
                  <Spinner animation="grow" size="sm" />
                  <Spinner animation="grow" />
                </>
              ) : (
                "Forgot password?"
              )}
            </button>
          </div>

          {error && (
            <Alert variant="danger" className="mt-3 mb-0">
              {error}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} type="submit" className="w-100 mt-3">
            {loading ? (
              <>
                <Spinner animation="grow" size="sm" />
                <Spinner animation="grow" />
              </>
            ) : (
              "Login"
            )}
          </Button>
          <div className="d-flex justify-content-center w-100 p-3  ">
            <GoogleLogin
              size="large"
              width={400}
              onSuccess={(response) =>
                loginWithGoogleToken(response?.credential!.toString())
              }
              onError={() => console.log("Login Failed")}
            />
          </div>

          {/* google login button custom */}
          {/* <div className="w-100 d-flex justify-content-center ">

            <Button
              variant="light"
              className="w-100 mt-3"
              onClick={() => loginWithGoogle()}
            >
              <img src="/media/icons/google.svg" alt="google logo" style={{
                height: "20px",
                width: "20px",
              }} /> Login with Google
            </Button>
          </div> */}
          {/* {!hasCloseButton && (
            <div
              className="w-100 d-flex justify-content-center pt-2"
              style={{ borderTop: "1px solid gainsboro" }}
              onClick={() => {
                navigate("/");
                onClose();
              }}
            >
              <Button variant="success">Go to Homepage</Button>
            </div>
          )} */}
          {showSignUpBtn && (
            <div
              className="w-100 pt-2"
              style={{ borderTop: "1px solid gainsboro" }}
              onClick={() => {
                onClose();
                if (openRegisterModal) openRegisterModal();
              }}
            >
              <Button className="w-100" variant="success">
                Sign Up
              </Button>
            </div>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default LoginModal;
