import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import { MathJaxContext } from "better-react-mathjax";
import axios from "axios";
import "./styles/global.css";
import "./styles/main.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import { setupAxios } from "./utils/AuthHelpers";
import { AuthInit, AuthProvider } from "./context/auth";
import { SplashScreenProvider } from "./utils/SplashScreen";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";

const element = document.getElementById("root") as HTMLElement;
const root = createRoot(element);
const queryClient = new QueryClient();
setupAxios(axios);

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId="781496603045-ocioa66dffvrh565mcfl5rrbijtpm8bv.apps.googleusercontent.com">
      <SplashScreenProvider>
        <AuthProvider>
          <AuthInit>
            <MathJaxContext>
              <QueryClientProvider client={queryClient}>
                <App />
              </QueryClientProvider>
            </MathJaxContext>
          </AuthInit>
        </AuthProvider>
      </SplashScreenProvider>
    </GoogleOAuthProvider>
  </Provider>
);
