import { FC } from "react";
import { Image } from "react-bootstrap";
const NoData: FC<{ name?: string }> = ({ name }) => {
  return (
    <div className="d-flex align-items-center justify-content-center vh-90">
      <div className="text-center">
        <Image src="/media/images/not-found.svg" height="500px" width="500px" />
        <h3 className="text-primary fw-bold pt-3">
          {name || "No data available"}
        </h3>
      </div>
    </div>
  );
};
export default NoData;
