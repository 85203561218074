import { FC } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Banner } from "../../models/homepageModel";

interface Props {
  banners?: Banner[];
}

const Hero: FC<Props> = ({ banners }) => {
  return (
    <div className="container-fluid   position-relative mt-2">
      <Carousel>
        {banners?.map((banner) => (
          <Carousel.Item key={banner.id}>
            <div
              style={{
                backgroundColor: "transparent",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }}
            />
            <img
              //TODO BASEURL FILLUP
              src={
                banner?.feature_image || "/media/icons/no-image-placeholder.svg"
              }
              alt="education"
              style={{ width: "100%", objectFit: "fill", aspectRatio: "12/4", borderRadius: "10px" }}
            />
            {/* <div
              className="position-absolute d-flex flex-column align-items-center w-50"
              style={{
                left: "50%",
                top: "25%",
                transform: "translate(-50%, 0%)",
              }}
            >
              <h1 className="mb-3 text-larger text-white text-center w-">
                Prepare for your next journey !
              </h1>

              {/* For when data is available */}
            {/* {banner?.title && (
                <h1 className="mb-3 text-larger text-white text-center w-">
                  {banner?.title}
                </h1>
              )} */}
            {/* {banner?.title && (
                <h1 className="mb-3 text-larger text-white text-center w-">
                  {banner?.title}
                </h1>
              )} */}

            {/* For when data is available */}
            {/* {banner?.subtitle && (
                <p className="mb-8 fs-5 fw-semi-semibold text-white text-center">
                  {banner?.subtitle}
                </p>
              )} */}
            {/* <p className="mb-8 fs-5 fw-semi-semibold text-white text-center">
                {banner?.subtitle && (
                  <p className="mb-8 fs-5 fw-semi-semibold text-white text-center">
                    {banner?.subtitle}
                  </p>
                )}
              </p> */}
            {/* {banner?.callback && (
                <a
                  className="btn btn-lg btn-primary mt-2 py-2 px-4 shadow"
                  href={banner?.callback}
                >
                  Get Started
                </a>
              )} */}
            {/* </div> */}
          </Carousel.Item>
        ))}
      </Carousel>
      {/* <div
        className="position-absolute d-flex flex-column align-items-center w-50"
        style={{ left: "50%", top: "25%", transform: "translate(-50%, 0%)" }}
      >
        <h1 className="mb-3 text-larger text-white text-center w-">
          Prepare for your next journey !
        </h1>
        <p className="mb-8 fs-5 fw-semi-semibold text-white text-center">
          Entrance Preparation for M.Sc Programs at IOE.
        </p>
        <a className="btn btn-lg btn-primary mt-2 py-2 px-4 shadow" href="#">
          Get Started
        </a>
      </div> */}
    </div>
  );
};

export default Hero;
